import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../shared/custom-buttons/primary-button/primary-button";
import styles from "./info-block.module.css"

/**
	@param {string?} align left - position the image on the left, the text on the right
	@param {string?} align right - position the image on the right, the text on the left
*/

const InfoBlock = ({
	imgPath,
	blockStyles,
	secondImgPath,
	prefixTitleText,
	titleIcon,
	descriptionText,
	align = "left",
	productName,
	buttonName,
	bulets,
	secondImgStyles
}) => {
	const navigate = useNavigate()

	return (
		<div className={`${styles.info_block} ${styles[align]}`} style={blockStyles}>
			<h3 className={`${styles.title} ${styles.title_mobile}`}>
				{titleIcon && titleIcon}
				{prefixTitleText}
			</h3>

			<div className={styles.img_block}>
				<img className={styles.screenshot} src={imgPath} alt="screenshot-icon" />
				{secondImgPath && (
					<img className={styles.second_screenshot} src={secondImgPath} alt="second-screenshot-icon" style={secondImgStyles} />
				)}
			</div>

			<div className={styles.title_block}>
				<h3 className={`${styles.title}`}>
					{titleIcon && titleIcon}
					{prefixTitleText}
				</h3>

				<p className={`${styles.description}`}>{descriptionText}</p>
				{
					bulets && (
						<div>
							<p className={styles.bulet_title}>How can it help you?</p>
							<ul className={styles.bulets}>
								{
									bulets.map((bullet, index) => (
										<li key={index} className={styles.bulet}>
											{bullet}
										</li>
									))
								}
							</ul>
						</div>
					)
				}

				{
					productName && (
						<PrimaryButton
							className={styles.learn_button}
							onClick={() => navigate(buttonName ? "/contact" : `/${productName}`)}
							type="blue"
						>
							{buttonName ?? "Learn More"}
						</PrimaryButton>
					)
				}
			</div>
		</div>
	);
};

export default InfoBlock;