import PrimaryButton from "../../../shared/custom-buttons/primary-button/primary-button"
import { ReactComponent as WebsiteIcon } from "../../../shared/img/icon-website.svg"
import { ReactComponent as ViewProfileIcon } from "../../../shared/img/icon-view-profile.svg"
import { ReactComponent as ContactIcon } from "../../../shared/img/icon-contact.svg"
import { ReactComponent as FavoritesIcon } from "../../../shared/img/icon-favorites.svg"
import styles from "./company-card.module.css"
import { handleLinkClick, handlerBudget, handlerRate, handlerSize } from "../../helpers/companies-list-helpers"
import { useLocation, useNavigate } from "react-router-dom"
import { ServicesColors } from "../../../shared/services-colors/services-colors"
import { Tooltip } from "antd"
import { useState } from "react"
import ProLabel from "./pro-label"
import { calculationType } from "../../../../service/utils"

const CompanyCard = ({ data, searchTag }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [isVisible, setIsVisible] = useState();

	let {
		focus_calculation_type,
		focus_groups,
		company_photo_src,
		company_name,
		company_short_description,
		hq_location,
		minimal_budget,
		rates,
		size,
		rating_score,
	} = data || {};

	const sortTags = () => {
		let copyArray = structuredClone(focus_groups);
		let filterData = copyArray.filter(elem => elem.percentages > 0)

		return filterData.sort(function (a, b) {
			if (a.id === searchTag) {
				return -1;
			} else {
				return 1;
			}
		})
	}

	const handlerVisibleRate = () => {
		setIsVisible(prev => !prev);
	}

	let currentTagName = focus_groups.filter(elem => elem.id === searchTag)[0]?.focus_name

	return (
		<div className={styles.company_card}>
			<div className={styles.company_title}>
				{/* <div className={`${styles.sponsored} main-gray fs12 fw500 lh16`}>sponsored</div> */}
				<div className={styles.header_block}>
					<img src={company_photo_src ?? "/img/company-logo.svg"} alt="company-logo" />
					<div className={styles.title_block}>
						<div className={styles.title}>
							<h3 className="fs20 fw500 lh24">{company_name}</h3>
						</div>

						<p className={`${styles.description} main-gray fs13 fw500 lh16`}>{company_short_description}</p>
					</div>
				</div>

				<div className={styles.footer_block}>
					<p className="main-gray fs13 fw500 lh16">Service Focus:</p>
					<div className={styles.scale}>
						{
							sortTags()
								.map((elem, i) => (
									<Tooltip
										placement="top"
										key={i}
										overlayClassName={styles.card_tooltip}
										title={`${elem.percentages}% ${elem.focus_name}`}
									>
										<div key={i} style={{ width: `${elem?.percentages}%`, background: ServicesColors[i], height: "100%" }} />
									</Tooltip>
								))}
					</div>
					<p className="dark-blue fs13 fw500 lh18">
						{focus_groups.filter(elem => elem.id === searchTag)[0]?.percentages}% {currentTagName}
					</p>
				</div>
				{focus_calculation_type === calculationType.PRO && <ProLabel />}
			</div>

			<div className={styles.company_info}>
				<div className={styles.info_row} onClick={handlerVisibleRate}>
					<img src="/img/icon-timezone.svg" alt="icon" />
					<p className={`${styles.value} fs14 fw500 lh20`}>{hq_location}</p>
				</div>
				<div className={styles.info_row}>
					<img src="/img/icon-project-size.svg" alt="icon" />
					<p className={`${styles.value} fs14 fw500 lh20`}>{handlerBudget(minimal_budget)}</p>
				</div>
				<div className={styles.info_row}>
					<img src="/img/icon-hourly-rate.svg" alt="icon" />
					<p className={`${styles.value} fs14 fw500 lh20`}>{handlerRate(rates)}</p>
				</div>
				<div className={styles.info_row}>
					<img src="/img/icon-employees.svg" alt="icon" />
					<p className={`${styles.value} fs14 fw500 lh20`}>{handlerSize(size)}</p>
				</div>
				{
					isVisible && (
						<div className={styles.info_row}>
							<img src="/img/icon-location-gray.svg" alt="icon" />
							<p className={`${styles.value} fs14 fw500 lh20`}>{rating_score}</p>
						</div>
					)
				}
			</div>
			<div className={styles.company_buttons}>
				<PrimaryButton
					className={`${styles.website_button}`} type="blue"
					onClick={() => handleLinkClick(data.website, searchTag)}
				>
					<WebsiteIcon />
					Visit Website
				</PrimaryButton>

				<PrimaryButton className={styles.link_button} onClick={() => navigate(`/vendors/${company_name}/profile`, { state: { data, searchTag } })} type="no_background">
					<ViewProfileIcon />
					View Profile
				</PrimaryButton>

				<PrimaryButton className={styles.link_button} onClick={() => navigate(`/vendors/${company_name}/contact`, { state: { data, prevPath: location.pathname } })} type="no_background">
					<ContactIcon />
					Contact
				</PrimaryButton>

				{/* <PrimaryButton className={styles.link_button} type="no_background">
					<FavoritesIcon />
					Add to Favorites
				</PrimaryButton> */}
			</div>
		</div>
	)
}

export default CompanyCard