import { Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import LandingCard from "./card/LandingCard";
import styles from "./PricingPage.module.css";
import { planNames, plansDescription } from "../../../service/utils";
import i18next from "i18next";
import { links } from "../../shared/constants/constants";
import { useNavigate } from "react-router-dom";
const { t } = i18next;


const plans = {
	free: { name: "free", title: "Basic", price: 5 },
	standard: { name: "standard", title: t("shared.plans.pro-label"), price: 10 },
	pro: { name: "pro", title: t("shared.plans.enterprise-label"), price: 10 },
};

const PricingPage = () => {
	// const { t } = useTranslation();
	const [isMonthlyPeriodSelected, setIsMonthlyPeriodSelected] = useState(true);
	const [selectedCard, setSelectedCard] = useState(plans.free);
	const [usersAmount, setUsersAmount] = useState(10);
	const navigate = useNavigate()

	const handleChangeUsersAmount = (amount) => {
		setUsersAmount(amount);
	};

	const handleRedirect = () => {
		navigate("/contact")
	}
	return (
		<div className={styles.pricing_page}>
			<div className={styles.background}>
				<div className={`${styles.content} container`}>
					<h1 className={`${styles.page_title}`}>{t("landing-page.pricing-label")}</h1>

					<div className={styles.amount_block}>
						<p className={`${styles.title} fs24 fw400 lh28`}>
							{t("landing-page.pricing.how-many-users-text")}
						</p>
						<Input value={usersAmount} onChange={(e) => handleChangeUsersAmount(e.target.value)} className={styles.amount_input} />
					</div>

					<div className={styles.discount_wrapper}>
						<div className={styles.discount_info}>
							<p>
								{t("landing-page.pricing.save-up-text")}
							</p>
						</div>
						<div className={styles.period_block}>
							<p className={`${styles.period_name} ${styles.annually} ${!isMonthlyPeriodSelected && styles.selected} fs24 fw400 lh28`}>{t("shared.annually-label")}</p>
							<Switch onChange={setIsMonthlyPeriodSelected} checked={isMonthlyPeriodSelected} className={styles.switch} />
							<p className={`${styles.period_name} ${styles.monthly} ${isMonthlyPeriodSelected && styles.selected} fs24 fw400 lh28`}>{t("shared.monthly-label")}</p>
						</div>

					</div>


					<div className={styles.cards_grid}>
						<div className={styles.cards_block}>
							<div className={`${styles.card} ${selectedCard === plans.free && styles.active} ${((selectedCard === plans.free) && !isMonthlyPeriodSelected) && styles.disabled}`}>
								<LandingCard
									isActive={selectedCard === plans.free}
									isMonthlyPeriodSelected={isMonthlyPeriodSelected}
									title={plans.free.title}
									price={plans.free.price}
									amountPrice={plans.free.price * usersAmount * 0.8}
									info2={isMonthlyPeriodSelected ? `$${plans.free.price * usersAmount}` : `$${plans.free.price * usersAmount * 0.8 * 12}`}
									buttonText={"Request demo"}
									onButtonClick={handleRedirect}
									features={plansDescription[planNames.free]}
								/>
							</div>

							<div className={`${styles.card} ${selectedCard === plans.standard && styles.active}`}>
								<LandingCard
									isActive={selectedCard === plans.standard}
									isMonthlyPeriodSelected={isMonthlyPeriodSelected}
									title={plans.standard.title}
									price={plans.standard.price}
									amountPrice={plans.standard.price * usersAmount * 0.8}
									info2={isMonthlyPeriodSelected ? `$${plans.standard.price * usersAmount}` : `$${plans.standard.price * usersAmount * 0.8 * 12}`}
									buttonText={"Request demo"}
									onButtonClick={handleRedirect}
									features={plansDescription[planNames.standard]}
								/>
							</div>

							<div className={`${styles.card} ${selectedCard === plans.pro && styles.active}`}>
								<LandingCard
									isEnterprise={true}
									isActive={selectedCard === plans.pro}
									isMonthlyPeriodSelected={isMonthlyPeriodSelected}
									title={plans.pro.title}
									price={(isMonthlyPeriodSelected && plans.pro.price) || (plans.pro.price * usersAmount * 0.8)}
									buttonText={"Talk to sales"}
									onButtonClick={handleRedirect}
									features={plansDescription[planNames.pro]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default PricingPage;