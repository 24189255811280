import { ProductList } from "../ProductPage/utils";

export const listOfCards = [
	{
		title: "Employee Directory",
		descr: "Streamline employee management with an Employee Directory and Org Chart products. Create detailed employee cards, manage roles, store documents, and configure access for seamless people and compensation management.",
		imgPath: "/img/imgCards/employee_directory.png",
		productName: ProductList.employee_directory,
	},
	{
		title: "Vacations Tracker",
		descr: "Manage employee time-offs efficiently with the Vacation tracker. Set location-based policies, track balances, automate approvals, and handle bonus days and penalties with ease. Full visibility for managers and HR.",
		imgPath: "/img/imgCards/vt.png",
		productName: ProductList.vacation_tracker,
	},
	{
		title: "Timesheets",
		descr: "Time tracking can be easy with our Timesheet solution. Track project and non-project time, automate vacation integration, enable approvals, generate reports, and export data to Excel for analysis",
		imgPath: "/img/imgCards/timesheets.png",
		productName: ProductList.time_tracker,
	},
	{
		title: "Compensation Planning",
		descr: "Manage employee compensation efficiently with our Compensation Planning tool. Draft and approve salary changes, analyse impact on profitability, control access to sensitive data, and streamline payroll integration.",
		imgPath: "/img/imgCards/cp.png",
		productName: ProductList.compensation_planning,
	},
	{
		title: "Sales Orders & Invoices",
		descr: "Effortlessly manage sales orders and invoices. Auto-fill customer details, integrate with timesheets, adjust taxes and discounts, generate PDF invoices, track payments, and support multiple currencies.",
		imgPath: "/img/imgCards/sales_orders.png",
		productName: ProductList.sales_order,
	},
	{
		title: "Custom Roles",
		descr: "Define custom roles with precise access control. Tailor permissions for org units, locations, or projects, manage sensitive data securely, and apply over 150 policies for viewing, editing, or managing data.",
		imgPath: "/img/imgCards/custom_roles.png",
		productName: ProductList.define_roles,
	},
	{
		title: "Job Applications Tracker",
		descr: "Streamline recruitment with our ATS. Create job openings, configure hiring pipelines, manage candidates, store feedback, assign hiring roles, and integrate with your org structure for efficient decision-making.",
		imgPath: "/img/imgCards/job_applications_tracker.png",
		productName: ProductList.job_application_tracker,
	},
	{
		title: "Workforce Planning",
		descr: "Optimise workforce utilisation with our Workforce Planning solution. Track employee assignments, workload, profitability, and identify risks of bench growth and assignment termination to prevent revenue losses.",
		imgPath: "/img/imgCards/workforce_planning.png",
		productName: ProductList.workforce_planning,
	},
	{
		title: "Project P&L",
		descr: "Track and forecast project profitability with our Project P&L tool. Analyse costs, employee contributions, forecast risks, and get insights by project, department, or location to ensure financial success.",
		imgPath: "/img/imgCards/pl.png",
		productName: ProductList.profit_loss_management,
	},
	{
		title: "Companies Listing",
		descr: "Publish your company profile and attract more leads with our Company Listing. Showcase services, rates, and budgets. Pro companies get top visibility, rated by their employee skills and experience.",
		imgPath: "/img/imgCards/companies_listing.png",
		productName: ProductList.software_companies,
	},
];
