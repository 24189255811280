export const handleSearchTag = (tag) => {
	switch (tag) {
		case "mobile-development":
			return "Mobile Development";
		case "web-development":
			return "Web Development";
		case "cloud":
			return "Cloud Development";
		default:
			return "Mobile Development";
	}
};

export function handlerCurrency(amount, currency) {
	if (amount) {
		if (currency) {
			return `${currency}${amount
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
		} else {
			return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	} else {
		return amount;
	}
}

export function formatSearchTag(input) {
	if (!input) {
		return "";
	}

	const words = input.split("-");
	const formattedWords = words.map(
		(word) => word.charAt(0).toUpperCase() + word.slice(1)
	);
	return formattedWords.join(" ");
}

export const handlerBudget = (budget) => {
	switch (budget) {
		case 1:
			return `${handlerCurrency(5000, "$")}+`;
		case 2:
			return `${handlerCurrency(10000, "$")}+`;
		case 3:
			return `${handlerCurrency(25000, "$")}+`;
		case 4:
			return `${handlerCurrency(50000, "$")}+`;
		case 5:
			return `${handlerCurrency(75000, "$")}+`;
		case 6:
			return `${handlerCurrency(100000, "$")}+`;
		case 7:
			return `${handlerCurrency(250000, "$")}+`;
		default:
			return;
	}
};

export const handlerRate = (budget) => {
	const getBudgetString = (value) => {
		switch (value) {
			case 1:
				return "< $15/hr";
			case 2:
				return "$15 - $24/hr";
			case 3:
				return "$25 - $49/hr";
			case 4:
				return "$50 - $74/hr";
			case 5:
				return "$75 - $99/hr";
			case 6:
				return "$100+";
			default:
				return "";
		}
	};

	if (budget.length === 1 || budget.length === 0) {
		return getBudgetString(budget[0]);
	} else {
		const minBudget = Math.min(...budget);
		const maxBudget = Math.max(...budget);

		const minBudgetString = getBudgetString(minBudget)
			.split(" - ")[0]
			.replace("/hr", "");
		const maxBudgetString =
			maxBudget === 6
				? getBudgetString(maxBudget)
				: getBudgetString(maxBudget).split(" - ")[1].replace("/hr", "");

		const combinedBudgetString = `${minBudgetString} - ${maxBudgetString}/hr`;
		return combinedBudgetString;
	}
};

export const handlerSize = (budget) => {
	switch (budget) {
		case 1:
			return "1 - 10";
		case 2:
			return "11 - 50";
		case 3:
			return "51 - 150";
		case 4:
			return "151 - 500";
		case 5:
			return "500+";
		default:
			return "";
	}
};

export const handlerSearchTagName = (tagname) => {
	switch (tagname) {
		case "mobile-development":
			return "Mobile Apps Development";
		case "web-development":
			return "Web Development";
		case "database-development":
			return "Database Development";
		case "cloud":
			return "Cloud Applications Development";
		case "big-data":
			return "Big Data Development";
		case "bi":
			return "Business Intelligence";
		case "machine-learning":
			return "Machine Learning";
		case "natural-language-processing":
			return "Natural Language Processing";
		case "ecommerce-development":
			return "Ecommerce Development";
		case "seo":
			return "Search Engine Optimization";
		case "game-development":
			return "Game Development";
		case "cms-development":
			return "CMS Development";
		case "aem-development":
			return "AEM Development";
		case "iot":
			return "Internet of Things";
		case "devops":
			return "Top DevOps Companies";
		case "rpa":
			return "Robotic Process Automation";
		case "salesforce":
			return "Salesforce Development";
		case "blockchain":
			return "Blockchain Development";
		case "ux-ui-design":
			return "UX/UI Design";
		case "web-design":
			return "Web Design";
		case "ar-vr":
			return "AR/VR Development";
		case "startup-development":
			return "Startup Development";
		case "qa":
			return "Quality Assurance";
		default:
			return "";
	}
};

export const handleLinkClick = (website, searchTag) => {
	window.open(
		`${website}?utm_campaign=directory-${searchTag}&utm_medium=referral&utm_source=techfirms.io`,
		"_blank"
	);
};

export const industries = [
	"Augmented reality",
	"Automotive",
	"Banking & Financial Services",
	"Blockchain",
	"Chemical",
	"eCommerce",
	"eLearning",
	"High-Tech",
	"Gaming",
	"Goverment Solutions",
	"Internet of Things",
	"Insurance",
	"Logistics & Transportation",
	"MarTech",
	"Medicine & Healthcare",
	"Media & Entertainment",
	"Real Estate",
	"Retail",
	"Telecom",
	"Travel & Hospitality",
	"Other",
];

export const initialActivitiesList = [
	{ label: "Mobile Apps development", value: "mobile-development" },
	{ label: "Web Development", value: "web-development" },
	{ label: "Cloud Applications Development", value: "cloud" },
	{ label: "Database Development", value: "database-development" },
	{ label: "Big Data", value: "big-data" },
	{ label: "Business Intelligence", value: "bi" },
	{ label: "Machine Learning", value: "machine-learning" },
	{
		label: "Natural Language Processing (NLP)",
		value: "natural-language-processing",
	},
	{ label: "Ecommerce Development", value: "ecommerce-development" },
	{ label: "Search Engine Optimization", value: "seo" },
	{ label: "Game Development", value: "game-development" },
	{ label: "CMS Development", value: "cms-development" },
	{ label: "Internet of Things", value: "iot" },
	{ label: "DevOps", value: "devops" },
	{ label: "Robotic Process Automation", value: "rpa" },
	{ label: "Salesforce", value: "salesforce" },
	{ label: "Blockchain", value: "blockchain" },
	{ label: "UX/UI Design", value: "ux-ui-design" },
	{ label: "Web Design", value: "web-design" },
	{ label: "AR/VR", value: "ar-vr" },
	{ label: "Startup Development", value: "startup-development" },
	{ label: "Quality Assurance", value: "qa" },
];

export const initialActivitiesListinSelect = [
	{ label: "Mobile Apps development", value: "mobile-development" },
	{
		label: "Cross Platform Mobile Apps Development",
		value: "cross-platform-mobile-development",
	},
	{
		label: "Native Mobile Apps Development",
		value: "native-mobile-development",
	},
	{
		label: "iOS Mobile Apps Development",
		value: "ios-mobile-apps-development",
	},
	{
		label: "Android Mobile Apps Development",
		value: "android-mobile-apps-development",
	},
	{ label: "Web Development", value: "web-development" },
	{ label: "Cloud Applications Development", value: "cloud" },
	{ label: "Database Development", value: "database-development" },
	{ label: "Big Data", value: "big-data" },
	{ label: "Business Intelligence", value: "bi" },
	{ label: "Machine Learning", value: "machine-learning" },
	{
		label: "Natural Language Processing (NLP)",
		value: "natural-language-processing",
	},
	{ label: "Ecommerce Development", value: "ecommerce-development" },
	{ label: "Search Engine Optimization", value: "seo" },
	{ label: "Game Development", value: "game-development" },
	{ label: "CMS Development", value: "cms-development" },
	{ label: "Internet of Things", value: "iot" },
	{ label: "DevOps", value: "devops" },
	{ label: "Robotic Process Automation", value: "rpa" },
	{ label: "Salesforce", value: "salesforce" },
	{ label: "Blockchain", value: "blockchain" },
	{ label: "UX/UI Design", value: "ux-ui-design" },
	{ label: "Web Design", value: "web-design" },
	{ label: "AR/VR", value: "ar-vr" },
	{ label: "Startup Development", value: "startup-development" },
	{ label: "Quality Assurance", value: "qa" },
];

export let rateList = [
	{ value: 1, label: "< $15" },
	{ value: 2, label: "$15 - $24" },
	{ value: 3, label: "$25 - $49" },
	{ value: 4, label: "$50 - $74" },
	{ value: 5, label: "$75 - $99" },
	{ value: 6, label: "$100+" },
];

export let sizeList = [
	{ value: 1, name: "1 - 10" },
	{ value: 2, name: "11 - 50" },
	{ value: 3, name: "51 - 150" },
	{ value: 4, name: "151 - 500" },
	{ value: 5, name: "500+" },
];

export let budgetList = [
	{ value: 1, name: "5000" },
	{ value: 2, name: "10000" },
	{ value: 3, name: "25000" },
	{ value: 4, name: "50000" },
	{ value: 5, name: "75000" },
	{ value: 6, name: "100000" },
	{ value: 7, name: "250000" },
];
