import React from "react";
import styles from "./index.module.css";
import FooterColumn from "./FooterColumn";
import { footerColumns } from "./utils";
import { Link } from "react-router-dom";
import { deviceTypes, useDeviceType } from "../hooks/useDeviceType";

const FooterComponent = () => {
	let { contactUs, forTeam, products, companyFocus, products_next } = footerColumns || {};
	const deviceType = useDeviceType();


	const isComputerSize = deviceType !== deviceTypes.TABLET && deviceType !== deviceTypes.MOBILE
	return (
		<footer className={styles.footer}>
			<div className={styles.footer_container}>
				<div >
					<button
						// onClick={() => history.push("/")} 
						className={styles.logo_button}
					>
						<img src="/img/techfirms-logo.svg" alt="logo" />
					</button>
				</div>
				<div className={styles.footer_columns__wrapper} style={{ gap: !isComputerSize && "28px" }}>
					<div style={{ order: !isComputerSize && 2 }}>
						<FooterColumn
							title={contactUs.title}
							options={contactUs.options}
							link={<a className={styles.link_icon} href={contactUs.link} target="_blank" rel="noreferrer">{contactUs.linkIcon}</a>}
						/>
					</div>
					<div className={styles.footer_column__wrapper} style={{ order: !isComputerSize && 1 }}>

						<FooterColumn
							title={products.title}
							options={isComputerSize ? products.options : [...products.options, ...products_next.options]}
						/>
						{isComputerSize && (
							<FooterColumn
								title={products_next.title}
								options={products_next.options}
							/>

						)}
					</div>
				</div>
				<div className={styles.copyright}>
					©Techfirms 2024. All rights reserved. <Link to="/privacy-policy" className={styles.copyright_link}>Privacy Policy</Link> and <Link to="/terms-service" className={styles.copyright_link}>Terms of Services</Link>.
				</div>
			</div>
		</footer>
	);
};

export default FooterComponent;