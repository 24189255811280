import styles from "./popular-card.module.css"

const PopularCard = ({ title, iconPath, categoriesList }) => {
	return (
		<div className={styles.popular_card}>
			<div className={styles.title_block}>
				<h3 className={`${styles.title} fs20 fw500 lh24`}>{title}</h3>
			</div>

			<div className={styles.categories_list}>
				{categoriesList.map(item => {
					const { name } = item

					return (
						<a key={name} href={item.link} className={`${styles.category} fs14 fw400 lh16`}>{name}</a>
					)
				})}
			</div>
		</div>
	)
}

export default PopularCard