import React from 'react'
import styles from "../index.module.css"
import Options from './Options'

const OPTIONS = ["Request a demo", "Learn which plan is right for your team", "Get onboarding help"]

const DescriptionBlock = () => {
	return (
		<div className={styles.description_block}>
			<h2 className={styles.header}>See <span className='dark-blue'>Techfirms</span> in action</h2>
			<p className={styles.description}>
				Techfirms.io is unified cloud software, designed to help you break down silos between departments and increase organisational efficiency.
			</p>
			<Options options={OPTIONS} />
			<p className={styles.description}>You can also email us at <a href={"mailto:contact@techfirms.io"} target="_blank" rel="noreferrer" className='dark-blue'>contact@techfirms.io</a></p>
		</div>
	)
}

export default DescriptionBlock