import styles from "./top-menu.module.css"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import { links } from "../constants/constants"
import useScroll from "../../hooks/useScroll"
import { ReactComponent as TechfirmsAI } from "../../shared/img/techfirms-ai.svg"
import { Modal } from "antd"
import { ReactComponent as CloseIcon } from "../../shared/img/modal-close.svg"
import JoinModal from "./components/modals/join-modal"
import * as Scroll from "react-scroll"
import BurderMenu from "../../burger-menu/BurderMenu"

const TopMenu = ({ isCompact = false, hasBackground = true, borderBottom = false, children }) => {
	const navigate = useNavigate()
	const isScrolled = useScroll();
	const location = useLocation();
	const [isModalOpen, setIsModalOpen] = useState(false)
	const scroller = Scroll.scroller;

	const isChatPage = location.pathname === "/ai-chat";

	const handleJoin = () => {
		navigate("/contact")
	}

	const handleCancel = () => {
		setIsModalOpen(false);
	}

	const goToPagePageAndScroll = async (block) => {
		navigate("/")
		setTimeout(() => {
			scroller.scrollTo(block)
		}, 200)
	}

	return (
		<div
			className={`
				${styles.top_menu_block} 
				${(hasBackground) && styles.with_background} 
				${(borderBottom || isScrolled) && styles.border_bottom}`
			}
		>
			<div className={`${styles.top_menu}`}>
				<div className={`${styles.content} container`}>
					<button
						onClick={() => {
							navigate("/")
							Scroll.animateScroll.scrollToTop()
						}}
						className={styles.logo_button}
					>
						{
							isChatPage ? (
								<TechfirmsAI />
							) : (
								<img src="/img/techfirms-logo.svg" alt="logo" />
							)
						}
					</button>

					<div className={styles.buttons_block}>
						{!isCompact && (
							<div className={styles.dropdown_buttons}>
								<button
									onClick={() => {
										navigate("/search")
										Scroll.animateScroll.scrollToTop()
									}}
									className={`${styles.pricing_button}`}
								>
									Search for Tech Partner
								</button>
								<button onClick={() => goToPagePageAndScroll("pricing")} className={`${styles.pricing_button}`} >
									Pricing
								</button>
								<Link to={`/contact`} className={`${styles.pricing_button}`} >
									Contact
								</Link>
							</div>
						)}

						{children && children}

						<div className={styles.started_buttons}>
							<a
								href={`${links.vendors}sign-in`}
								className={`${styles.login_button} fs15 fw500 lh18 dark-blue`}
							>
								Login
							</a>
							<button
								// href={`${links.vendors}sign-up`}
								onClick={handleJoin}
								className={`${styles.get_started_button} fs15 fw500`}
							>
								Request demo
							</button>
						</div>
					</div>
					<BurderMenu />
				</div>
			</div>
			<Modal
				title="What is your company focus?"
				open={isModalOpen}
				onCancel={handleCancel}
				closeIcon={<CloseIcon />}
				footer={false}
				destroyOnClose={true}
				width={460}
				closable
				centered
			>
				<JoinModal />
			</Modal>
		</div>
	)
}

export default TopMenu