import PrimaryButton from "../shared/custom-buttons/primary-button/primary-button"
import PageNavigationBlock from "../shared/page-navigation-block/page-navigation-block"
import TopMenu from "../shared/top-menu/top-menu"
import { ReactComponent as WebsiteIcon } from "../shared/img/icon-website.svg"
import { ReactComponent as ContactIcon } from "../shared/img/icon-contact.svg"
import { ReactComponent as FavoritesIcon } from "../shared/img/icon-favorites.svg"
import styles from "./company-profile.module.css"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import { ServicesColors } from "../shared/services-colors/services-colors"
import { useEffect, useState } from "react"
import { handleLinkClick, handlerBudget, handlerRate, handlerSearchTagName, handlerSize } from "../companies-list/helpers/companies-list-helpers"
import FooterComponent from "../footer/FooterComponent"

const CompanyProfile = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const { state } = location

	const [isInfoTextOpen, setIsInfoTextOpen] = useState(false);
	const [isMoreThanFourLines, setIsMoreThanFourLines] = useState(false);

	const data = state?.data
	const focusGroups = data?.focus_groups.filter(elem => elem.percentages > 0)
	const companyName = data?.company_name
	const searchTag = state?.searchTag

	useEffect(() => {
		document.body.style.backgroundColor = "white";

		const textElement = document.getElementById('company_description');
		const textHeight = textElement?.scrollHeight;
		const isMoreThanFourLines = textHeight > 88;
		setIsMoreThanFourLines(isMoreThanFourLines);

		let newTitle = `${companyName}`
		document.title = newTitle;

		return () => {
			document.body.style.backgroundColor = "";
			document.title = "Techfirms";
		};
	}, []);

	if (!state) {
		return <Navigate to="/" />
	}

	return (
		<div className={styles.company_page}>

			<div className={`${styles.content} container`}>
				<PageNavigationBlock
					redirectLink={`/`}
					redirectPageName={`Home / ${handlerSearchTagName(searchTag)}`}
					currentPageName={companyName}
				/>

				<div className={styles.company_info_block}>
					<div className={styles.company_title_block}>
						<div className={styles.title_block}>
							<img src={data?.company_photo_src ? data.company_photo_src : "/img/company-logo.svg"} alt="company-logo-icon" />
							<div className={styles.title}>
								<h1 className="fs34 fw500 lh40">{companyName}</h1>
								<p className="fs14 fw500 lh20 main-gray">{data.company_short_description}</p>
							</div>
						</div>

						<p className={`${styles.company_info_text} ${isInfoTextOpen && styles.open} fs15 fw500 lh22`} id="company_description">
							{data.full_description}
						</p>
						{
							isMoreThanFourLines && (
								<PrimaryButton
									type="no_background"
									className={styles.read_more_button}
									onClick={() => setIsInfoTextOpen(prev => !prev)}
								>
									{
										isInfoTextOpen ? "Read Less" : "Read more"
									}
								</PrimaryButton>
							)
						}
					</div>
					<div className={styles.buttons_block}>
						<PrimaryButton
							className={styles.website_button}
							type="blue"
							onClick={() => handleLinkClick(data.website, searchTag)}>
							<WebsiteIcon />
							Visit Website
						</PrimaryButton>

						<PrimaryButton
							className={styles.contact_button}
							onClick={() => navigate(`/vendors/${data.company_name}/contact`, { state: { data, prevPath: location.pathname, searchTag } })}
							type="no_background"
						>
							<ContactIcon />
							Contact
						</PrimaryButton>
					</div>
				</div>

				<div className={styles.company_data_block}>
					<div className={styles.data_block}>
						<h3 className={`${styles.title} fs24 fw500 lh28`}>Key Info</h3>
						<div className={styles.info_rows}>
							<div className={styles.info_row}>
								<img src="/img/icon-project-size.svg" alt="icon" />
								<p className={`${styles.value} fs14 fw400 lh20`}>{handlerBudget(data.minimal_budget)}</p>
							</div>

							<div className={styles.info_row}>
								<img src="/img/icon-hourly-rate.svg" alt="icon" />
								<p className={`${styles.value} fs14 fw400 lh20`}>{handlerRate(data.rates)}</p>
							</div>

							<div className={styles.info_row}>
								<img src="/img/icon-employees.svg" alt="icon" />
								<p className={`${styles.value} fs14 fw400 lh20`}>{handlerSize(data.size)}</p>
							</div>
						</div>
					</div>

					<div className={styles.data_block}>
						<h3 className={`${styles.title} fs24 fw500 lh28`}>Locations</h3>
						<div className={styles.info_columns}>
							<div className={styles.info_column}>
								{/* <p className={`${styles.column_title} main-gray fs14 fw500 lh20`}>Headquarters</p> */}
								<div className={styles.info_rows} style={{ rowGap: "12px" }}>
									<div className={styles.info_row}>
										<img src="/img/icon-location-gray.svg" alt="icon" />
										<p className={`${styles.value} fs14 fw400 lh20`}>{data.hq_location}</p>
									</div>

									<div className={styles.info_row}>
										<img src="/img/icon-phone.svg" alt="icon" />
										<p className={`${styles.value} fs14 fw400 lh20`}>{data.phone}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.focus_block}>
					<h3 className={`${styles.title} fs24 fw500 lh28`}>Focus</h3>

					<div className={styles.lines_block}>
						<p className={`${styles.line_title} main-gray fs14 fw500 lh20`}>Service lines</p>
						<div className={styles.line}>
							{focusGroups.map((item, i) => (
								<div key={i} className={styles.part} style={{ width: `${item.percentages}%`, background: ServicesColors[i], height: "100%" }}>{item.percentages}%</div>
							))}
						</div>
						<div className={styles.groups}>
							{focusGroups.map((item, i) => {
								return (
									<div key={item.focus_name} className={styles.group}>
										<div className={styles.color_block} style={{ background: ServicesColors[i] }} />
										<p className="fs13 fw400 lh20">{item.focus_name}</p>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				<div className={styles.buttons_block_mobile}>
					<PrimaryButton
						className={styles.website_button}
						type="blue"
						onClick={() => handleLinkClick(data.website, searchTag)}>
						<WebsiteIcon style={{ height: "27.66px", width: "12.66px" }} />
						Visit Website
					</PrimaryButton>

					<PrimaryButton
						className={styles.contact_button}
						onClick={() => navigate(`/vendors/${data.company_name}/contact`, { state: { data, prevPath: location.pathname, searchTag } })}
						type="blue"
					>
						<ContactIcon style={{ fill: "white", strokr: "white" }} />
						Contact
					</PrimaryButton>
				</div>
			</div>
			<FooterComponent />
		</div>
	)
}

export default CompanyProfile