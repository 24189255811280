import { Typography } from "antd";
import React from "react";
import styles from "./LandingCard.module.css";
import PrimaryButton from "../../../shared/custom-buttons/primary-button/primary-button";
const LandingCard = ({
	isActive,
	title,
	price,
	info1,
	info2,
	buttonText,
	onButtonClick,
	features,
	link,
	linkText,
	isDisabled,
	isMonthlyPeriodSelected,
	isEnterprise,
	amountPrice,
	linkInfo
}) => {
	return (
		<div className={`${styles.card_content} ${isActive && styles.active} ${isDisabled && styles.disabled}`}>
			<h3 className={`${styles.card_title} fs26 fw500`}>{title}</h3>

			<div className={styles.white_background}>
				<div className={styles.pricing_block}>
					{
						isEnterprise ? (
							<>
								<p className={` ${styles.enterprice_title} fs24 fw700 lh28`}>Custom Solution</p>
								<p className={` ${styles.enterprice_descr} fs16 fw400 lh20`}>adopted for your business</p>
							</>
						) : (
							(price || price === 0) && (
								<Typography.Title
									ellipsis={{
										tooltip: price
									}}
								>
									{`$${isMonthlyPeriodSelected ? price : price * 0.8}`}
									<span className={styles.prefix}>/mo per user</span>
								</Typography.Title>
							)
						)
					}

					{info2 && (
						<div className={styles.info_block}>
							{/* <p className="fs16 fw400 lh20">{info1}</p> */}
							{info2 && (
								<p className="fs16 fw600 lh20">{info2}{isMonthlyPeriodSelected ? <span className="fw400"> billed monthly</span> : <span className="fw400"> billed annualy</span>}</p>
							)}
						</div>
					)}

					{(link && linkText && linkInfo) && (
						<div className={styles.link_block}>
							<a href={link} className={styles.link}>{linkText}</a>
							<p className={`${styles.link_info} fs14 fw400 lh20`}>{linkInfo}</p>
						</div>
					)}
				</div>

				<div className={styles.button_block}>
					{
						isDisabled ? (
							<div style={{ height: "38px" }}></div>
						) : (
							<PrimaryButton
								onClick={onButtonClick}
								className={styles.button}
								isEmpty={!isActive}
							>
								{buttonText}
							</PrimaryButton>
						)
					}
				</div>

				<div className={styles.options_block}>
					{features.map((item, i) => (
						<div className={styles.option} key={i}>
							{item.isShowCheckIcon && <img src="/img/icon-check-green.svg" alt="check-icon" />}
							<p className="fs15 fw500 lh18" style={{ marginLeft: !item.isShowCheckIcon && "0px" }}>{item.title}</p>
						</div>
					))}
				</div>
			</div>
			<div className={`${styles.button_block} ${styles.button_block_mobile}`}>
				{
					isDisabled ? (
						<div style={{ height: "38px" }}></div>
					) : (
						<PrimaryButton
							onClick={onButtonClick}
							className={styles.button}
							isEmpty={!isActive}
						>
							{buttonText}
						</PrimaryButton>
					)
				}
			</div>
		</div>
	);
};

export default LandingCard;