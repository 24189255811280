
import CompanySearchBlock from "../shared/company-search-block/company-search-block";
import styles from "./main-page.module.css"
import AIBlock from "../ai-components/AIBlock";
import FooterGroup from "../footer/FooterGroup";

const MainPage = () => {
	return (
		<div className={styles.main_page}>
			<div className={styles.background}>
				<div className={styles.find_company_block}>
					<div>
						<CompanySearchBlock />
					</div>
				</div>
				{/* <AIBlock /> */}
			</div>
			<FooterGroup />
		</div>
	)
}

export default MainPage