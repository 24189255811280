import { useState, useEffect } from "react";

const deviceTypes = {
	MOBILE: "mobile",
	TABLET: "tablet",
	DESKTOP: "desktop",
};

const useDeviceType = () => {
	const [deviceType, setDeviceType] = useState(deviceTypes.DESKTOP);

	useEffect(() => {
		if (typeof window === "undefined") return;

		const handleResize = () => {
			if (window.innerWidth <= 768) {
				setDeviceType(deviceTypes.MOBILE);
			} else if (window.innerWidth <= 900) {
				setDeviceType(deviceTypes.TABLET);
			} else {
				setDeviceType(deviceTypes.DESKTOP);
			}
		};

		handleResize(); // Устанавливаем начальное значение
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return deviceType;
};

export { useDeviceType, deviceTypes };
