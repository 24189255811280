import i18n from "../locales/i18n";

const { t } = i18n;
const I18_KEY_PREFIX = "landing-page.pricing";

export const calculationType = {
	PRO: 0,
	NOT_PRO: 1,
};

export const planNames = {
	free: t("shared.plans.free-label"),
	standard: t("shared.plans.standard-label"),
	pro: t("shared.plans.pro-label"),
};
export const planTypes = {
	monthly: t("shared.monthly-label"),
	annually: t("shared.annually-label"),
};

export const plansDescription = {
	[planNames.free]: [
		{ title: "Includes:", isShowCheckIcon: false, isBold: true },
		{ title: t("shared.employee-directory-label"), isShowCheckIcon: true },
		{ title: t("shared.vacations-label"), isShowCheckIcon: true },
		{ title: t("shared.time-journals-label"), isShowCheckIcon: true },
		{ title: t("shared.sales-orders-label"), isShowCheckIcon: true },
		{ title: t("shared.companies-listing-label"), isShowCheckIcon: true },
	],
	Free: [
		{ title: "Includes:", isShowCheckIcon: false, isBold: true },
		{ title: t("shared.employee-directory-label"), isShowCheckIcon: true },
		{ title: t("shared.vacations-label"), isShowCheckIcon: true },
		{ title: t("shared.time-journals-label"), isShowCheckIcon: true },
		{ title: t("shared.sales-orders-label"), isShowCheckIcon: true },
		{ title: t("shared.companies-listing-label"), isShowCheckIcon: true },
	],
	[planNames.standard]: [
		{
			title: "Everything from Basic plus:",
			isShowCheckIcon: false,
			isBold: true,
		},
		{ title: t("shared.workforce-label"), isShowCheckIcon: true },
		{
			title: t("shared.compensation-planning-label"),
			isShowCheckIcon: true,
		},
		{ title: t("shared.job-applications-label"), isShowCheckIcon: true },
		{ title: t("shared.pl-label"), isShowCheckIcon: true },
		{ title: t("shared.custom-roles-label"), isShowCheckIcon: true },
	],
	[planNames.pro]: [
		{
			title: "Everything from Pro plus:",
			isShowCheckIcon: false,
			isBold: true,
		},
		{ title: "Custom Extensions", isShowCheckIcon: true },
		{ title: "New Business Modules", isShowCheckIcon: true },
		{ title: "Custom Integrations", isShowCheckIcon: true },
		{ title: "On Premise Installation", isShowCheckIcon: true },
	],
};
