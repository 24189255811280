import React from "react";
import styles from "./index.module.css";
import * as Scroll from "react-scroll";
import { Link, useNavigate } from "react-router-dom";

const FooterLink = ({ title, link, isComingSoon, type, target, rel, productName }) => {
	const navigate = useNavigate()
	const scroller = Scroll.scroller;

	const onPageLoad = () => {
		if (productName) {
			navigate(`/${productName}`)

			setTimeout(() => {
				scroller.scrollTo(productName)
			}, 100)
		}
	};
	return (
		<div className={styles.footer_link}>
			{
				link ? (
					<a href={link} target={target} rel={rel} className={styles.footer_link__link}>{title}</a>
				) : (
					<Link to={`/${productName}`} onClick={onPageLoad} className={styles.footer_link__title}>{title}</Link>
				)
			}
			{
				isComingSoon && (
					<p className={styles.footer_link__tag}>{"COMING SOON"}</p>
				)
			}
		</div>
	);
};

export default FooterLink;