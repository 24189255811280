import { Button, Form, Input, Radio, Upload } from "antd"
import TextArea from "antd/es/input/TextArea"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../shared/close-icon/close-icon"
import PrimaryButton from "../shared/custom-buttons/primary-button/primary-button"
import TopMenu from "../shared/top-menu/top-menu"
import { ReactComponent as IconClose } from "../shared/img/icon-close.svg"
import styles from "./company-contact.module.css"
import { useEffect, useState } from "react"

const accept = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";

const CompanyContact = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { state } = location
	const [filesList, setFilesList] = useState([]);

	const data = state?.data
	const companyName = data.company_name
	const prevPath = state?.prevPath

	useEffect(() => {
		document.body.style.backgroundColor = "white";
		return () => {
			document.body.style.backgroundColor = "";
		};
	}, []);

	const beforeUpload = (file, allowedTypes, uploadFiles) => {
		if (allowedTypes.includes(file.type)) {
			setFilesList(prev => [...prev, file]);
			return true;
		} else {
			return false;
		}
	};

	const onRemove = (file) => {
		setFilesList(prev => {
			let mappedDocumentsList = prev.filter(elem => elem.name !== file.name);
			return mappedDocumentsList;
		});
	};

	if (!state) {
		return <Navigate to="/" />
	}

	return (
		<div className={styles.company_contact}>

			<div className="container">
				<div className={styles.contact_block}>
					<CloseButton
						onClick={() => navigate(prevPath, { state: { data, prevPath: location.pathname, searchTag: state?.searchTag } })}
						iconStyles={{ width: "16px", height: "16px" }}
					/>

					<div className={styles.form_block}>
						<h3 className={`${styles.company_name} fs34 fw500 lh40`}>{companyName}</h3>
						<p className={`${styles.description} fs15 fw300 lh22`}>{`Contact ${companyName} using the form below`}</p>

						<Form className={styles.form}>
							<Form.Item
								className={styles.full_name_row}
								name="fullName"
								label="Full Name"
							>
								<Input className={styles.input} />
							</Form.Item>

							<Form.Item
								className={styles.email_row}
								name="email"
								label="Contact Email"
							>
								<Input className={styles.input} />
							</Form.Item>
							<p className={`${styles.email_info} fs13 fw400 lh16`}>To change your Contact Email, go to your <span style={{ color: "#2D64BC" }}>Account Page</span>.</p>

							<div className={styles.company_info}>
								<Form.Item
									name="companyName"
									label="Company Name"
								>
									<Input
										className={styles.input}
										placeholder="Your Company Name"
									/>
								</Form.Item>

								<Form.Item
									name="phone"
									label="Phone (optional)"
								>
									<Input
										className={styles.input}
										placeholder="+1 (555) 555-5555"
									/>
								</Form.Item>
							</div>

							<Form.Item
								name="subject"
								label="Subject"
								className={styles.radio_group}
							>
								<Radio.Group>
									<Radio value={1}>Get a quote/discuss my project needs</Radio>
									<Radio value={2}>Propose a partnership opportunity</Radio>
									<Radio value={3}>Find a job</Radio>
									<Radio value={4}>Other</Radio>
								</Radio.Group>
							</Form.Item>

							<Form.Item
								label="Message"
								name="message"
								className={styles.textarea}
							>
								<TextArea
									autoSize={{ minRows: 6, maxRows: 6 }}
									placeholder="Add your message you’d like to send"
								/>
							</Form.Item>

							<Form.Item>
								<Upload
									fileList={filesList}
									onRemove={onRemove}
									// action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
									accept={accept}
									beforeUpload={(file, fileList) => beforeUpload(file, accept, fileList)}
									listType="picture"
									showUploadList={{
										removeIcon: (
											<IconClose className={styles.remove_icon} />
										)
									}}
								>
									<Button className={styles.attachment_button}>
										<img src="/img/attachment.svg" alt="attachment-icon"></img>
										<p className={styles.text}>Add Attachment</p>
									</Button>
								</Upload>
							</Form.Item>

							<PrimaryButton
								type="blue"
								className={styles.submit_button}
							>
								Submit
							</PrimaryButton>

							<p className={`${styles.submit_info} fs12 fw400 lh16`}>By pressing submit, your message along with the information provided above will be sent to the provider.</p>
						</Form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CompanyContact