import React, { useEffect } from 'react'
import { useState } from 'react';
import { ReactComponent as CloseIcon } from "../shared/img/menu-close-icon.svg"
import styles from "./BurgerMenu.module.css"
import { useNavigate } from 'react-router-dom';
import * as Scroll from "react-scroll"
import { links } from '../shared/constants/constants';
import { ReactComponent as ContactsIcon } from "../shared/img/contacts_button_icon.svg"

const BurderMenu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate()
	const scroller = Scroll.scroller;

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const closeMenu = () => {
		setIsOpen(false);
	};

	const handleJoin = () => {
		navigate("/contact")
	}

	const goToPagePageAndScroll = async (block) => {
		navigate("/")
		setTimeout(() => {
			scroller.scrollTo(block)
		}, 200)
	}

	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}
		// Очистка класса при размонтировании компонента
		return () => document.body.classList.remove('no-scroll');
	}, [isOpen]);

	const handleMenuClick = (e) => {
		// Если клик произошел на элементе, который является ссылкой или кнопкой внутри меню, закрываем меню
		if (e.target.closest(`.${styles.option}`)) {
			closeMenu();
		}
	};
	return (
		<div className={styles.burger_menu}>
			{isOpen && <div className={styles.menu_backdrop} onClick={closeMenu}></div>}
			<div className={styles.icons_wrapper}>
				<button
					onClick={() => {
						navigate("/contact")
						Scroll.animateScroll.scrollToTop()
					}}
					className={`${styles.contacts_button}`}
				>
					<ContactsIcon />
				</button>
				{/* Бургер-иконка */}
				<div className={styles.burger_icon} onClick={toggleMenu}>
					<span className={isOpen ? `${styles.bar} ${styles.bar_open}` : styles.bar}></span>
					<span className={isOpen ? `${styles.bar} ${styles.bar_open}` : styles.bar}></span>
					<span className={isOpen ? `${styles.bar} ${styles.bar_open}` : styles.bar}></span>
				</div>
			</div>

			{/* Выезжающее меню */}
			<nav
				className={`${styles.menu} ${isOpen ? styles.menu_open : ''}`}
				onClick={handleMenuClick}
			>
				{/* Кнопка закрытия меню */}
				<span className={styles.menu_close} onClick={closeMenu}><CloseIcon /></span>

				{/* Ссылки меню */}

				<button
					onClick={() => {
						navigate("/search")
						Scroll.animateScroll.scrollToTop()
					}}
					className={`${styles.option}`}
				>
					Search for Tech Partner
				</button>
				<button
					onClick={() => goToPagePageAndScroll("pricing")}
					className={`${styles.option}`}
				>
					Pricing
				</button>
				<button
					// href={`${links.vendors}sign-up`}
					onClick={handleJoin}
					className={`${styles.option}`}
				>
					Request demo
				</button>
				<a
					href={`${links.vendors}sign-in`}
					className={`${styles.option}`}

				>
					Login
				</a>

			</nav>
		</div >
	);
};

export default BurderMenu