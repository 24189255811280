import React from 'react'
import FooterComponent from '../footer/FooterComponent'
import { Element } from 'react-scroll'
import InfoBlock from '../main-page/components/info-block/info-block'
import styles from "./index.module.css"
import { useTranslation } from 'react-i18next'
import LandingTag from './LandingTag'
import PricingPage from './Pricing/PricingPage'
import { ReactComponent as TimeTrackerIcon } from "../shared/img/time-tracker-icon.svg"
import { ReactComponent as VacationTrackerIcon } from "../shared/img/vacation-tracker-icon.svg"
import { ReactComponent as CustomRolesICon } from "../shared/img/custom-roles-icon.svg"
import { ReactComponent as JobApplicationsICon } from "../shared/img/job-applications.svg"
import { ReactComponent as WorkforceIcon } from "../shared/img/workforce-icon.svg"
import { ReactComponent as FinancesIcon } from "../shared/img/icon-finances.svg"
import { ReactComponent as PLIcon } from "../shared/img/projects-icon.svg"
import { ReactComponent as CompensationIcon } from "../shared/img/compensation-icon.svg"
import { ReactComponent as CompensationsListingIcon } from "../shared/img/companies-listing-icon.svg"
import { ReactComponent as PeopleIcon } from "../shared/img/people-icon.svg"
import PrimaryButton from '../shared/custom-buttons/primary-button/primary-button'
import { ProductList } from '../ProductPage/utils'
import { useNavigate } from 'react-router-dom'

const LandingPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate()

	const TAGS = [
		t("shared.hr-management-label"),
		t("shared.time-journals-label"),
		t("shared.vacations-label"),
		t("shared.project-management-label"),
		t("shared.resources-planning-label"),
		t("shared.finances-label"),
		t("shared.analytics-label")
	];

	return (
		<>
			<div className={styles.main_page}>
				<div className={styles.background}>
					<div className={`${styles.content} ${styles.container}`}>
						<div className={styles.main_block}>
							<h1 className={`${styles.title} ${styles.title_block} fs50 fw500 lh60`}>All-in-one <span className="dark-blue">Operating Platform</span> for tech companies</h1>
							<div className={styles.tags_wrapper}>
								{TAGS.map(item => (<LandingTag title={item} key={item} />))}
							</div>
							<p className={`${styles.description} ${styles.title_block} main-gray fs24 fw400 lh34`}>Run your entire business on Techfirms.io with our unified cloud software, designed to help you break down silos between departments and increase organisational efficiency.</p>
							<PrimaryButton
								className={styles.demo_button}
								onClick={() => navigate("/contact")}
								type="blue"
							>
								Request demo
							</PrimaryButton>
						</div>
					</div>
				</div>

				<div className={styles.info_cases_block}>
					<div className={"container"}>
						<div className={styles.info_cases}>

							<InfoBlock
								blockStyles={{ paddingTop: "0px" }}
								imgPath="/img/people-illustration.png"
								prefixTitleText={t("shared.employee-directory-label")}
								titleIcon={<PeopleIcon className={styles.title_icon} />}
								descriptionText={t("landing-page.people-description")}
								productName={ProductList.employee_directory}
								bulets={[
									"Efficient onboarding with centralised employee profiles and documents.",
									"Create org units and assign roles with specific data access.",
									"Empower employee self-service for updating profiles (skills, past experience etc.) and accessing documents.",
									"Manage promotions, role changes, mobility between locations and departments.",
									"Track team performance and streamline people management tasks.",
									"Facilitate cross-department collaboration with easy employee search and org visualisation.",
								]}
							/>
							<InfoBlock
								imgPath="/img/vacation-tracker-illustration.png"
								prefixTitleText={t("shared.vacations-label")}
								titleIcon={<VacationTrackerIcon className={styles.title_icon} />}
								descriptionText={t("landing-page.vacations-description")}
								secondImgStyles={{
									right: "38px",
									bottom: "-170px",
									widthL: "417px"

								}}
								align="right"
								productName={ProductList.vacation_tracker}
								bulets={[
									"Define location-based leave policies and rules.",
									"Automate leave request approvals with manager workflows.",
									"Track real-time vacation balances and carry-forward days.",
									"View an activity feed for all changes and approvals.",
									"Apply bonus days or penalties to employee leave.",
									"Provide managers with an accurate leave balance sheet for team planning.",
								]}
							/>
							<InfoBlock
								imgPath="/img/time-tracker-illustration.png"
								prefixTitleText={t("shared.time-journals-label")}
								titleIcon={<TimeTrackerIcon className={styles.title_icon} />}
								descriptionText={t("landing-page.time-journals-description")}
								productName={ProductList.time_tracker}
								bulets={[
									"Track time by projects and non-project activities taking into account allocation percentage.",
									"Automatically integrate vacations into timesheets.",
									"Set approval workflows for timesheets by managers.",
									"Generate detailed, consolidated reports for managers.",
									"Export timesheets to Excel for deeper analysis.",
									"Configure time tracking based on employee roles and needs.",
								]}
							/>
							<InfoBlock
								imgPath="/img/compensation-planning-illustration.png"
								align="right"
								prefixTitleText={t("shared.compensation-planning-label")}
								titleIcon={<CompensationIcon className={styles.title_icon_white} />}
								descriptionText={t("landing-page.compensation-planning-description")}
								productName={ProductList.compensation_planning}
								bulets={[
									"Draft and approve salary and bonus proposals with manager workflows.",
									"Analyse salary changes’ impact on total compensation and profitability of org unit, project or entire location.",
									"Track detailed compensation history for each employee.",
									"Control access to sensitive salary data with configurable permissions by role.",
									"Integrate approved compensation data with payroll for seamless processing.",
								]}
							/>
							<InfoBlock
								imgPath="/img/sales-orders-illustration.png"
								prefixTitleText={t("shared.sales-orders-label")}
								titleIcon={<FinancesIcon className={`${styles.title_icon} ${styles.stroke_fill}`} />}
								descriptionText={t("landing-page.sales-orders-description")}
								productName={ProductList.sales_order}
								bulets={[
									"Auto-fill customer details and billing information.",
									"Integrate with timesheets to automatically add billable hours and rates.",
									"Customise taxes, discounts, and other adjustments.",
									"Generate and share professional PDF invoices with clients.",
									"Link sales orders to invoices for seamless payment tracking.",
									"Manage multi-currency transactions for international clients.",
								]}
							/>
							<InfoBlock
								imgPath="/img/custom-roles-illustration.png"
								prefixTitleText={t("shared.custom-roles-label")}
								titleIcon={<CustomRolesICon className={styles.title_icon} />}
								align="right"
								descriptionText={t("landing-page.custom-roles-description")}
								productName={ProductList.define_roles}
								bulets={[
									"Create custom roles specific to your company’s needs.",
									"Apply roles to different contexts like org units, locations, or projects.",
									"Manage sensitive data access with over 150 policies for security.",
									"Define hierarchical roles for departments or locations.",
									"Restrict access to sensitive data like payroll or employee records.",
									"Adapt roles for temporary, external or project-based teams with tailored permissions.",
								]}
							/>
							<InfoBlock
								imgPath="/img/job-applications-illustration.png"
								prefixTitleText={t("shared.job-applications-label")}
								titleIcon={<JobApplicationsICon className={`${styles.title_icon} ${styles.stroke_fill}`} />}
								descriptionText={t("landing-page.job-applications-description")}
								productName={ProductList.job_application_tracker}
								bulets={[
									"Create detailed job openings with specific attributes and requirements.",
									"Customise hiring pipelines from sourcing to onboarding.",
									"Manage candidates with CVs, contact details, and notes.",
									"Store structured feedback to support data-driven hiring decisions.",
									"Assign roles within the hiring team for interviews and final decisions.",
									"Link job openings to organisational units for permission-based access."
								]}
							/>
							<InfoBlock
								imgPath="/img/workforce-illustration.png"
								prefixTitleText={t("shared.workforce-label")}
								titleIcon={<WorkforceIcon className={styles.title_icon_white} />}
								descriptionText={t("landing-page.workforce-description")}
								align="right"
								productName={ProductList.workforce_planning}
								bulets={[
									"Monitor employee utilisation across departments and locations.",
									"Track assignments with start/end dates to plan staffing needs.",
									"Identify risks of bench growth and proactively manage reallocations.",
									"Set margin targets and track profitability of employee assignments.",
									"Analyse workforce data to anticipate future hiring or resource needs.",
									"Flag assignment terminations to avoid project disruptions and revenue leakage."
								]}
							/>
							<InfoBlock
								imgPath="/img/pl-illustration.png"
								prefixTitleText={t("shared.pl-label")}
								titleIcon={<PLIcon className={styles.title_icon} />}
								descriptionText={t("landing-page.pl-description")}
								productName={ProductList.profit_loss_management}
								bulets={[
									"Track real-time project profitability using salary, rates, and billed hours.",
									"Forecast future project profitability and plan adjustments proactively.",
									"Analyse individual employee contribution and optimise resource allocation.",
									"Identify risks from salary changes, unreported time, or rate adjustments.",
									"Get comprehensive insights by project, department, or location.",
									"Generate detailed P&L reports for strategic financial decision-making."
								]}
							/>
							<InfoBlock
								imgPath="/img/companies-listing-illustration.png"
								prefixTitleText={t("shared.companies-listing-label")}
								titleIcon={<CompensationsListingIcon className={styles.title_icon_white} />}
								descriptionText={t("landing-page.companies-listing-description")}
								align="right"
								productName={ProductList.software_companies}
								bulets={[
									"Publish and manage detailed company profiles to attract leads.",
									"Showcase services, rates, and project preferences to potential clients.",
									"Gain top visibility as a Pro company based on employee skills and ratings.",
									"Drive traffic to your website and increase sales opportunities.",
									"Improve your company rating by investing in employee skills and expertise."
								]}
							/>
						</div>
					</div>
				</div>
				{/* <div className={styles.info_cases_block}> */}
				<Element name="pricing">
					<PricingPage />
				</Element>
				{/* </div> */}
			</div >
			<FooterComponent />
		</>
	)
}

export default LandingPage