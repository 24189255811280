import React, { useEffect } from 'react'
import DescriptionBlock from './components/DescriptionBlock'
import FormBlock from './components/FormBlock'
import FooterComponent from '../footer/FooterComponent'
import { animateScroll as scroll } from "react-scroll";
import styles from "./index.module.css"

const ContactPage = () => {

	useEffect(() => {
		scroll.scrollToTop({
			duration: 500, // Optional: sets the duration of the scroll
			smooth: "easeInOutQuad" // Optional: sets the easing function for the scroll
		});
	}, []);

	return (
		<section>
			<div className={`container ${styles.blocks_wrapper}`}>
				<DescriptionBlock />
				<FormBlock />
			</div>
			<FooterComponent />
		</section>
	)
}

export default ContactPage