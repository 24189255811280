import { useNavigate } from "react-router-dom"
import PrimaryButton from "../custom-buttons/primary-button/primary-button"
import styles from "./company-search-block.module.css"
import { Form } from "antd"
import { useForm } from "antd/es/form/Form"
import ActivitySelect from "../activity-select/activity-select"

const CompanySearchBlock = () => {
	const navigate = useNavigate()
	const [form] = useForm()

	const handleVendorButton = (value) => {
		if (value) {
			navigate(`/vendors/${value}`)
		} else {
			navigate(`/vendors`)
		}
	}

	const handleFinish = (e) => {
		const { activity } = e
		const activityValue = activity

		handleVendorButton(activityValue)
	}

	return (
		<div className={`${styles.search_block} container`}>
			<div className={styles.search_content}>
				<h1 className={`${styles.title} fs50 fw500 lh60`}>Discover the <span className="dark-blue">best tech partner</span> for your Business Needs</h1>
				<p className={`${styles.description} main-gray fs24 fw400 lh34`}>Find your perfect business partner with our service that ranks companies in real time by their employee competencies and availability.</p>

				<Form form={form} onFinish={handleFinish} className={styles.form}>
					<div className={styles.filters_block}>
						<p className={`${styles.looking_for_text} fs20 fw400 lh24`}>I am looking for</p>
						<div className={styles.activity_select}>
							<Form.Item
								name="activity"
								rules={[{ required: true }]}
								className="no-validation-message"
							>
								<ActivitySelect form={form} />
							</Form.Item>
						</div>
						{/* <p className={styles.in_text}>in</p>
						<div className={styles.location_select}>
							<Form.Item name="location">
								<LocationSelect />
							</Form.Item>
						</div> */}
						<PrimaryButton
							className={styles.find_button}
							onClick={() => form.submit()}
							type="blue"
						>
							Find Company
						</PrimaryButton>
					</div>
				</Form>
			</div>
		</div>
	)
}

export default CompanySearchBlock