import React from 'react'
import styles from "./index.module.css"
import { Link } from 'react-router-dom';

const DescrBlock = ({ data }) => {
	let { title, descr, imgPath, productName } = data || {};

	return (
		<Link to={`/${productName}`} className={styles.descr_block} >
			<div>
				<div className={styles.descr_block__title}>{title}</div>
				<p className={styles.descr_block__descr}>{descr}</p>
			</div>
			<img className={styles.descr_block__img} src={imgPath} alt="product_descr" />
		</Link>
	)
}

export default DescrBlock