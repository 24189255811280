import PopularCard from "../popular-card/popular-card"
import styles from "./popular-categories.module.css"

const developmentCategoriesList = [
	{ name: "Mobile App Development", link: "/vendors/mobile-development" },
	{ name: "Software Development", link: "/vendors/software-development" },
	{ name: "Web Development", link: "/vendors/web-development" },
	{ name: "AR/VR", link: "/vendors/AR-VR" },
	{ name: "Artificial Intelligence", link: "/vendors/artificial-intelligence" },
	{ name: "Blockchain", link: "/vendors/blockchain" }
]

const designCategoriesList = [
	{ name: "Web Design", link: "/vendors/web-design" },
	{ name: "User Experience Design", link: "/vendors/user-experience-design" },
	{ name: "Logo Design", link: "/vendors/logo-design" },
	{ name: "Graphic Design", link: "/vendors/graphic-design" },
	{ name: "Design", link: "/vendors/design" },
	{ name: "Digital Design", link: "/vendors/digital-design" }
]

const testingCategoriesList = [
	{ name: "Mobile App Development", link: "/vendors/mobile-development" },
	{ name: "Software Development", link: "/vendors/software-development" },
	{ name: "Web Development", link: "/vendors/web-development" },
	{ name: "AR/VR", link: "/vendors/ar-vr" },
	{ name: "Artificial Intelligence", link: "/vendors/artificial-intelligence" },
	{ name: "Blockchain", link: "/vendors/blockchain" }
]

const PopularCategories = () => {
	return (
		<div className={styles.popular_categories}>
			<PopularCard
				title="Development"
				categoriesList={developmentCategoriesList}
			/>

			<PopularCard
				title="Experience Design "
				categoriesList={designCategoriesList}
			/>

			<PopularCard
				title="Quality Assurance"
				categoriesList={testingCategoriesList}
			/>
		</div>
	)
}

export default PopularCategories