import { useNavigate } from "react-router-dom"
import styles from "./page-navigation-block.module.css"

const PageNavigationBlock = ({ redirectLink, redirectPageName, currentPageName }) => {
	const navigate = useNavigate()

	return (
		<div className={styles.navigation_block}>
			<button
				className={`${styles.redirect_button} main-gray fs14 fw400 lh20`}
				onClick={() => navigate(redirectLink)}
			>
				{redirectPageName}&nbsp;/&nbsp;
			</button>
			<p className={`${styles.current_page} fs14 fw400 lh20`}>{currentPageName}</p>
		</div>
	)
}

export default PageNavigationBlock