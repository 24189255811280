
import { Navigate, Route, Routes } from "react-router-dom"
import CompaniesList from "../components/companies-list/companies-list"
import CompanyContact from "../components/company-contact/company-contact"
import CompanyProfile from "../components/company-profile/company-profile"
import CompanySearch from "../components/company-search/company-search"
import CVHtml from "../components/cv-html/CVHtml"
import MainPage from "../components/main-page/main-page"
import { NotFoundPage } from "../components/not-found-page/NotFoundPage"
import AiChat from "../components/ai-components/AiChat"
import ContactPage from "../components/contact/ContactPage"
import LandingPage from "../components/landing-page/LandingPage"
import ProductPage from "../components/ProductPage/ProductPage"
import PrivacyPage from "../components/privacy-policy/PrivacyPage"
import TermsOfService from "../components/privacy-policy/TermsService"
// import PersonalPage from "../components/personal-page/personal-page"
// import VendorsPage from "../components/vendors-page/vendors-page"
// import PricingPage from "../components/pricing-page/pricing-page"

const Navigation = () => {
	return (
		<Routes>
			<Route exact path="/" element={<LandingPage />} />
			<Route exact path="/search" element={<MainPage />} />
			<Route exact path="/ai-chat" element={<AiChat />} />
			<Route exact path="/contact" element={<ContactPage />} />
			<Route exact path="/:product_name" element={<ProductPage />} />
			<Route exact path="/privacy-policy" element={<PrivacyPage />} />
			<Route exact path="/terms-service" element={<TermsOfService />} />
			{/* <Route exact path="/vendor-search" element={<CompanySearch />} /> */}
			<Route exact path="/vendors/:id/contact" element={<CompanyContact />} />
			<Route exact path="/vendors/:id/profile" element={<CompanyProfile />} />
			<Route exact path="/vendors" element={<CompaniesList />} />
			<Route exact path="/vendors/:search_tag" element={<CompaniesList />} />
			<Route exact path="/resume/:owner/:id" element={<CVHtml />} />
			<Route exact path="/404" element={<NotFoundPage />} />
			<Route path='*' element={<Navigate to="/404" />} />
			{/* <Route exact path="/personal" element={<PersonalPage />} />
            <Route exact path="/personal/:block" element={<PersonalPage />} /> */}
			{/* <Route exact path="/vendors" element={<VendorsPage />} />
            <Route exact path="/pricing" element={<PricingPage />} /> */}
		</Routes>
	)
}

export default Navigation