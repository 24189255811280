import React from 'react'
import styles from "../index.module.css"
import { ReactComponent as CheckedIcon } from "../../shared/img/icon-checked.svg"



const Option = ({ title }) => {
	return (
		<div className={styles.option}>
			<CheckedIcon />
			<p className={styles.title}>{title}</p>
		</div>
	)
}

const Options = ({ options }) => {
	return (
		<div className={styles.options}>
			{
				options.map((item, i) => (
					<Option title={item} key={i} />
				))
			}
		</div>
	)
}

export default Options