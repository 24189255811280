import React from 'react';
import PopularCategories from '../main-page/components/popular-categories/popular-categories';
import HelpBlock from '../help-block/HelpBlock';
import FooterComponent from './FooterComponent';
import Accordion from '../Accordion/Accordion'
import styles from "./index.module.css";

const accordionItems = [
	{
		title: 'Development',
		categories: [
			{ name: "Mobile App Development", link: "/vendors/mobile-development" },
			{ name: "Software Development", link: "/vendors/software-development" },
			{ name: "Web Development", link: "/vendors/web-development" },
			{ name: "AR/VR", link: "/vendors/AR-VR" },
			{ name: "Artificial Intelligence", link: "/vendors/artificial-intelligence" },
			{ name: "Blockchain", link: "/vendors/blockchain" }
		]
	},
	{
		title: 'Experience Design',
		categories: [
			{ name: "Web Design", link: "/vendors/web-design" },
			{ name: "User Experience Design", link: "/vendors/user-experience-design" },
			{ name: "Logo Design", link: "/vendors/logo-design" },
			{ name: "Graphic Design", link: "/vendors/graphic-design" },
			{ name: "Design", link: "/vendors/design" },
			{ name: "Digital Design", link: "/vendors/digital-design" }
		]
	},
	{
		title: 'Quality Assurance',
		categories: [
			{ name: "Mobile App Development", link: "/vendors/mobile-development" },
			{ name: "Software Development", link: "/vendors/software-development" },
			{ name: "Web Development", link: "/vendors/web-development" },
			{ name: "AR/VR", link: "/vendors/ar-vr" },
			{ name: "Artificial Intelligence", link: "/vendors/artificial-intelligence" },
			{ name: "Blockchain", link: "/vendors/blockchain" }
		]
	}
]
const FooterGroup = () => {
	return (
		<>
			<div className={`${styles.categories_wrapper} container`}>
				<div className={styles.popular_categories_mobile}>
					<h3 className={`${styles.title} fs20 fw500 lh24 main-gray`}>Popular Categories</h3>
					<Accordion items={accordionItems} />
				</div>
				<div className={styles.popular_categories}>
					<h3 className={`${styles.title} fs20 fw500 lh24 main-gray`}>Popular Categories</h3>
					<PopularCategories />
				</div>
			</div>
			<HelpBlock />
			<FooterComponent />
		</>
	)
}

export default FooterGroup