import React from 'react'
import DescrBlock from './DescrBlock'
import { listOfCards } from './utils'
import styles from "./index.module.css"

const HelpBlock = () => {
	return (
		<div className={`container ${styles.wrapper}`} style={{ paddingBottom: "120px" }}>
			<div className={styles.help_block}>
				<h2 className={styles.help_block__title}>Our solutions for <span className={styles.active}>Tech Companies</span></h2>
				<p className={styles.help_block__descr}>Run your entire business on Techfirms.io with our unified cloud software, designed to help you break down silos between departments and increase organisational efficiency.</p>
			</div>
			<div className={styles.help_block__descr_wrapper}>
				{listOfCards.map((item, i) => (
					<DescrBlock data={item} key={i} />
				))}
			</div>
		</div>
	)
}

export default HelpBlock