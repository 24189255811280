// Accordion.js
import React, { useState, useRef } from 'react';
import styles from './Accordion.module.css';

const Accordion = ({ items }) => {
	const [activeIndex, setActiveIndex] = useState(null);
	const contentRefs = useRef([]); // Array to store refs for each accordion content

	const toggleItem = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<div className={styles.accordion}>
			{items.map((item, index) => (
				<div key={index} className={styles.accordionItem}>
					<div className={styles.accordionHeader} onClick={() => toggleItem(index)}>
						<h3 className={styles.accordionTitle}>{item.title}</h3>
						<span
							className={`${styles.icon} ${activeIndex === index ? styles.iconOpen : ''}`}
						>
							{activeIndex === index ? '−' : '+'}
						</span>
					</div>
					<div
						ref={(el) => (contentRefs.current[index] = el)}
						className={`${styles.accordionContent} ${activeIndex === index ? styles.accordionContentOpen : ''
							}`}
						style={{

							opacity: activeIndex === index ? 1 : 0, // Add opacity transition
						}}
					>
						<ul>
							{item.categories.map((category, subIndex) => (
								<li key={subIndex}>
									<a href={category.link}>{category.name}</a>
								</li>
							))}
						</ul>
					</div>
				</div>
			))}
		</div>
	);
};

export default Accordion;
