export const ProductList = {
	employee_directory: "employee-directory",
	vacation_tracker: "vacation-tracker",
	time_tracker: "time-tracker",
	compensation_planning: "compensation-planning",
	sales_order: "sales-order",
	define_roles: "define-roles",
	job_application_tracker: "job-application-tracker",
	workforce_planning: "workforce-planning",
	profit_loss_management: "profit-loss-management",
	software_companies: "software-companies",
};
