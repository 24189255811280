import React from "react";
import { ReactComponent as LinkedinIcon } from "../shared/img/icon-linkedin.svg";
import i18n from "../../locales/i18n";
import { ProductList } from "../ProductPage/utils";
const { t } = i18n;

const googleMapsLink =
	"https://www.google.com/maps/search/?api=1&query=Poland+Warsaw%2C+st.+Nowogrodzka+31%2F413";

export const footerColumns = {
	contactUs: {
		title: "Contact Us",
		link: "https://www.linkedin.com/company/techfirmsio",
		linkIcon: <LinkedinIcon />,
		options: [
			{
				title: "Warsaw, st. Nowogrodzka 31/413",
				link: googleMapsLink,
				target: "_blank",
				rel: "noreferrer",
			},
			{
				title: "contact@techfirms.io",
				link: "mailto:contact@techfirms.io",
			},
		],
	},
	companyFocus: {
		title: "Solutions by Company Focus",
		options: [{ title: "Product Company" }, { title: "Service Provider" }],
	},
	forTeam: {
		title: "Solutions by Team",
		options: [
			{ title: "Marketing" },
			{ title: "Human Resources" },
			{ title: "Operations" },
			{ title: "PMO" },
		],
	},
	products: {
		title: "Our Solutions",
		options: [
			{
				title: t("shared.employee-directory-label"),
				productName: ProductList.employee_directory,
			},
			{
				title: t("shared.vacations-label"),
				productName: ProductList.vacation_tracker,
			},
			{
				title: t("shared.time-journals-label"),
				productName: ProductList.time_tracker,
			},
			{
				title: t("shared.compensation-planning-label"),
				productName: ProductList.compensation_planning,
			},
			{
				title: t("shared.sales-orders-label"),
				productName: ProductList.sales_order,
			},
		],
	},
	products_next: {
		title: "",
		options: [
			{
				title: t("shared.custom-roles-label"),
				productName: ProductList.define_roles,
			},
			{
				title: t("shared.job-applications-label"),
				productName: ProductList.job_application_tracker,
			},
			{
				title: t("shared.workforce-label"),
				productName: ProductList.workforce_planning,
			},
			{
				title: t("shared.pl-label"),
				productName: ProductList.profit_loss_management,
			},
			{
				title: t("shared.companies-listing-label"),
				productName: ProductList.software_companies,
			},
		],
	},
};
